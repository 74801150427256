import {api} from './index';
import {QrCode} from '../common/redux/entities/qrCode';
import {QrCodeManagementResponse} from '../common/redux/qrCodeManagement';
import {ActivateQrCodeRequestForm} from '../pages/Public/pages/ActivateQrCode/ActivateQrCode';
import {Machine} from '../common/redux/entities/machine';
import {GenerateQrCodesModalInterface} from '../pages/Configuration/QrCodeManagement/components/QrCodesModal/QrCodesModal';
import {info} from '../common/util/logger';

// @ts-ignore
const baseUrl = process.env.REACT_APP_API_URL;

export async function getQrCodeManagementState() {
  return (await api.get<QrCodeManagementResponse>('/qrcode')).data;
}

export async function generateQrCodes(value: GenerateQrCodesModalInterface) {
  return (await api.post<QrCode[]>('/qrcode/generate/', value)).data;
}

export async function archiveQrCode(id: number) {
  return (await api.get<QrCode>('/qrcode/archive/' + id)).data;
}

export async function validateQrCode(code: string) {
  return (await api.get('/qrcode/validate/' + code)).data;
}

export async function activateQrCode(activateRequest: ActivateQrCodeRequestForm) {
  return (await api.post('/qrcode/activate', activateRequest)).data;
}

export async function getMachineInformationBySerial(serialNumber: string) {
  info("Test getMachineInformationBySerial " + serialNumber);
  serialNumber = encodeURIComponent(serialNumber);

  return (await api.get<Machine>('/qrcode/machineDetails',{ params: { serialNumber: serialNumber }})).data;
}

export async function printQrCode(id: number) {
  return (await api.get<QrCode>('/qrcode/print/' + id)).data;
}

export function downloadQrCode(id: number) {
  window.open(`${baseUrl}qrcode/download/${id}`);
}
