import React, {useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, Dispatch} from 'redux';
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next';
import {QrCode, qrCodeStore} from '../../../../../common/redux/entities/qrCode';
import {machineStore} from '../../../../../common/redux/entities/machine';
import {WebState} from '../../../../../redux/types/WebState';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import GenerateQrCodesModal, {GenerateQrCodesModalInterface} from '../QrCodesModal/QrCodesModal';
import {Button} from 'react-bootstrap/es';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../../../router/RoutePaths';
import {machineTypeStore} from '../../../../../common/redux/entities/machineType';
import ToolkitProvider, {Search} from 'react-bootstrap-table2-toolkit';
import {LoadingButton} from '../../../../../components/util/widgets/LoadingButton/LoadingButton';
import Modal from 'react-bootstrap/Modal';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function QrCodes(props: Props) {
  const {activeQrCodes, archivedQrCodes, getMachineById, getMachineTypeById,
    actions: {archiveQrCode, generateQrCodes}} = props;
  const { SearchBar } = Search;
  const [isDeleting, setIsDeleting] = useState('');
  const [viewingArchived, setViewingArchived] = useState(false);
  const [viewGenerateQrCodesModal, setViewGenerateQrCodesModal] = useState(false);
  const [vividConfirmation, setVividConfirmation] = useState(false);

  const dataSet = viewingArchived?archivedQrCodes:activeQrCodes;

  const customTotal = (from: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined, to: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined, size: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined) => (
    <span className="react-bootstrap-table-pagination-total">
         &nbsp;&nbsp; Showing { from } to { to } of { size } Results
    </span>
  );
  
  const paginationOption = {
    paginationSize: 4,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    showTotal: true,
    hidePageListOnlyOnePage: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '10', value: 10
    },
    {
      text: '50', value: 50
    },{
      text: '100', value: 100
    }] // A numeric array is also available. the purpose of above example is custom the text

  };

  const deactivateFormatter = (cell: any, label: QrCode) => {
    return (
      
      <>
        {viewingArchived ? null :
          (
            <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
              <IconButton
                icon='minus'
                size={'1x'}
                iconToolTipText={'Deactivate QrCode'}
                onClick={() => setIsDeleting(label.id.toString())}
                color={'#005A9C'}
              />
            </div>
          )
        }
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      headerStyle: () => {
        return { width: '5rem'};
      },
      style: () => {
        return { width: '5rem'};
      }
    },
    {
      dataField: 'machineSerialNumber',
      text: 'test',
      hidden: true
    },
    {
      dataField: 'machineSerialNumberLink',
      isDummyField: true,
      text: 'Machine Serial Number',
      formatter: (cell: any, qrCode: QrCode) => {
        if (qrCode.machineId) {
          const machine = getMachineById(qrCode.machineId);
          if (machine)
          {
            return (
              <>
                <Link
                  to={RoutePaths.machinePaths.View
                    .replace(':machineCategoryId', String(getMachineTypeById(machine.machineTypeId).machineCategoryId))
                    .replace(':machineTypeId', String(machine.machineTypeId))
                    .replace(':machineId', String(machine.id))
                  }
                  color={'blue'}
                >
                  {machine.serialNumber}
                </Link>
              </>
            );
          } else {
            return  (<></>);
          }
        } else {
          return  (<></>);
        }
      }
    },
    {
      dataField: 'code',
      text: 'QR Code UUID'
    },
    {
      dataField: 'activeBoolean',
      text: 'Active',
      formatter: (cell: any, qrCode: QrCode) =>
        qrCode.machineId ? 'Yes' : 'No'
    },
    {
      dataField: 'archivedAt',
      hidden: !viewingArchived,
      text: 'Deactivated On'
    },
    {
      dataField: 'actionsColumn',
      text: 'Deactivate QR Code',
      isDummyField: true,
      formatter: deactivateFormatter,
      headerStyle: () => {
        return { width: '15rem'};
      },
      style: () => {
        return { width: '15rem'};
      }
    }
  ];


  return (
    <ToolkitProvider
      keyField='id'
      data={dataSet}
      columns={columns}
      search={{
        searchFormatted: true
      }}
    >
      {(tableProps) => (
        <>
          <div style={{ marginRight: '10px', display: 'flex', flexGrow: 1 }}>
            <div style={{ marginRight: '10px', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
              <div style={{ marginRight: '10px' }}>
                <Button
                  onClick={() => setViewingArchived(!viewingArchived)}
                  style={{ backgroundColor: '#005A9C' }}
                >
                  {viewingArchived ? 'View QR Codes' : 'View Deactivated QR Codes'}
                </Button>
              </div>
              <Button
                onClick={() => setViewGenerateQrCodesModal(true)}
                style={{ backgroundColor: '#005A9C' }}
              >
                Generate QR Code(s)
              </Button>
            </div>
          </div>
          <div>
            <SearchBar {...tableProps.searchProps} placeholder={'Search'} />
            <BootstrapTable
              {...tableProps.baseProps}
              pagination={paginationFactory(paginationOption)}
            />
          </div>
          {isDeleting !== '' && (
            <ConfirmationDialog
              onAccept={async () => {
                await archiveQrCode(isDeleting);
                setIsDeleting('');
              }}
              onDecline={async () => { setIsDeleting(''); }}
              open={isDeleting !== ''}
              prompt='Are you sure you want to deactivate this QR Code? This CANNOT be undone.'
              positiveText='Yes'
              negativeText='No'
              positiveVariant='danger'
            />
          )}
          {vividConfirmation ?
            <Modal
              show={vividConfirmation}
              aria-labelledby='contained-modal-title-vcenter'
              centered={true}
            >
              <Modal.Body>
                QR Codes sent to Vivid. You should receive a confirmation email from Vivid shortly.
              </Modal.Body>
              <Modal.Footer>
                <LoadingButton loading={false} variant={'primary'} onClick={() => setVividConfirmation(false)} label={'Close'} />
              </Modal.Footer>
            </Modal>
            : null}
          {viewGenerateQrCodesModal ?
            <GenerateQrCodesModal
              editable={true}
              onSubmit={(value: GenerateQrCodesModalInterface) => {
                console.log("View");

                generateQrCodes(value);
                setViewGenerateQrCodesModal(false);
                setVividConfirmation(true);
              }}
              onCancel={() => setViewGenerateQrCodesModal(false)}
            />
            : null}
        </>
      )}
    </ToolkitProvider>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    archiveQrCode: qrCodeStore.actions.archive,
    generateQrCodes: qrCodeStore.actions.generate,
    printQrCode: qrCodeStore.actions.print
  }, dispatch)});
const mapStateToProps = (state: WebState) => ({
  activeQrCodes: qrCodeStore.selectors.getNonArchivedQrCodes(state),
  archivedQrCodes: qrCodeStore.selectors.getArchivedQrCodes(state),
  getMachineById: machineStore.selectors.getById(state),
  getMachineTypeById: machineTypeStore.selectors.getById(state)
});
export default connect(mapStateToProps, mapDispatchToProps)(QrCodes);
