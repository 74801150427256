import {combineReducers} from 'redux';
import {DisclaimerAction, UserActions, userPersistConfig, users} from './user';
import {persistReducer} from 'redux-persist';
import {RoleActions, roles} from './role';
import {machineCategories, MachineCategoryActions} from './machineCategory';
import {MachineTypeActions, machineTypes} from './machineType';
import {MachineActions, machines} from './machine';
import {LabelActions, labels} from './label';
import {VideoActions, videos} from './video';
import {QrCodeActions, qrCodes} from './qrCode';

export const entities = combineReducers({
  users: persistReducer(userPersistConfig, users) as unknown as typeof users,
  roles: roles,
  machineCategories: machineCategories,
  machineTypes: machineTypes,
  machines: machines,
  labels: labels,
  videos: videos,
  qrCodes: qrCodes
});

export type Entities  = ReturnType<typeof entities>;

export type EntitiesActions =
  UserActions |
  RoleActions |
  MachineCategoryActions |
  MachineTypeActions |
  MachineActions |
  LabelActions |
  VideoActions |
  QrCodeActions |
  DisclaimerAction;
