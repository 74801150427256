import {Dispatch} from 'redux';
import {calculateSize} from '../util/object';
import {Dictionary} from '../util';
import {batchActions} from 'redux-batched-actions';
import {getMachineConfigurationState} from '../../api/machineConfigurationApi';
import {MachineCategory, machineCategoryStore} from './entities/machineCategory';
import {MachineType, machineTypeStore} from './entities/machineType';
import {Machine, machineStore} from './entities/machine';
import {Label, labelStore} from './entities/label';
import {Video, videoStore} from './entities/video';

export interface MachineConfigurationResponse {
  machineCategories: Dictionary<MachineCategory>;
  machineTypes: Dictionary<MachineType>;
  machines: Dictionary<Machine>;
  labels: Dictionary<Label>;
  videos: Dictionary<Video>;
}

export const loadMachineConfigurationData = () => async (dispatch: Dispatch) => {
  const response: MachineConfigurationResponse = await getMachineConfigurationState();
  if (process.env.NODE_ENV === 'development') {
    calculateSize(response, 'Machine Configuration Response');
  }
  await dispatch(batchActions([
    dispatch(machineCategoryStore.actions.set(response.machineCategories)),
    dispatch(machineTypeStore.actions.set(response.machineTypes)),
    dispatch(machineStore.actions.set(response.machines)),
    dispatch(labelStore.actions.set(response.labels)),
    dispatch(videoStore.actions.set(response.videos))
  ], 'BATCH_SET_MACHINE_CONFIGURATION_STATE'));
};
