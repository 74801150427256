import React from 'react';
import {WebState} from '../../../../../redux/types/WebState';
import {machineCategoryStore} from '../../../../../common/redux/entities/machineCategory';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {MachineType, machineTypeStore} from '../../../../../common/redux/entities/machineType';
import {RoutePaths, UrlParam} from '../../../../../router/RoutePaths';
import {useStandardEditor} from '../../../../../components/util/form-components/EditorForm/hooks';
import {makeMachineType} from '../../../../../common/util/factory';
import {Link, RouteComponentProps} from 'react-router-dom';
import {EditorForm} from '../../../../../components/util/form-components/EditorForm/EditorForm';
import {InputColumn} from '../../../../../components/util/form-components/InputColumn';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {convertToDropDownOptions} from '../../../../../common/util';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import StaticHeading from '../../../../../components/util/form-components/formik-inputs/Heading/StaticHeading';

type Props = RouteComponentProps<{machineCategoryId: string; machineTypeId: string}>
  & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function MachineTypeEditor(props: Props) {
  const {match: {params: {machineCategoryId, machineTypeId}}, getMachineTypeById, getMachineCategoryById} = props;

  const applyUrl = (url: string, aCategoryId: UrlParam, bTypeId: UrlParam) => url
    .replace(':machineCategoryId', String(aCategoryId))
    .replace(':machineTypeId', String(bTypeId));

  const getFieldName = (name: keyof MachineType) => name;
  const getMachineType = () => getMachineTypeById(Number(machineTypeId));
  const standardEditor = useStandardEditor<MachineType>(
    'Machine Type',
    makeMachineType(Number(machineCategoryId)),
    props,
    s => ({
      getEditUrl: () =>  applyUrl(RoutePaths.machineTypesPaths.Edit, machineCategoryId, machineTypeId),
      getCancelUrl: () => RoutePaths.machineTypeListPath.replace(':machineCategoryId', machineCategoryId),
      onLoadForm: async () => getMachineType(),
      onNewForm: async () => makeMachineType(Number(machineCategoryId)),
      saveAndRedirect: async (form) => {
        await props.actions.upsertMachineType(form);
        return RoutePaths.machineTypeListPath.replace(':machineCategoryId', machineCategoryId);
      },
      onDelete: async () => {
        await props.actions.deleteMachineType(Number(machineTypeId));
        return RoutePaths.machineTypeListPath.replace(':machineCategoryId', machineCategoryId);
      },
      footer: true,
      iconButtons: true
    }));
  const {editable} = standardEditor;

  const getPageHeaderArea = () => {
    const machineCategory = getMachineCategoryById(Number(machineCategoryId));
    const machineType = getMachineTypeById(Number(machineTypeId));
    return (
      <>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Link
            to={RoutePaths.machineTypeListPath.replace(':machineCategoryId', machineCategoryId)}
          >
            <IconButton
              icon={'angle-left'}
              size={'2x'}
              iconToolTipText={'Back'}
              color={'#005A9C'}
            />
          </Link>
          <div>
            <Link to={RoutePaths.machineCategoryListPath}>
              Machine Categories
            </Link>
            <label> {' > '} </label>
            {machineCategory.name}
            <label> {' > '} </label>
            <Link to={RoutePaths.machineTypeListPath.replace(':machineCategoryId', machineCategoryId)}>
              Machine Types
            </Link>
            <label> {' > '} </label>
            {machineType !== undefined ? machineType.name : 'New Machine Type'}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div style={{marginBottom: '10px', display: 'flex', alignItems: 'flex-end'}}>
        {getPageHeaderArea()}
      </div>
      <EditorForm standardEditor={standardEditor}>
        {({values}) => (
          <>
          {editable ?
            (
              <InputColumn label='' columnSize={3} style={{paddingLeft: 0}}>
                <Input name={getFieldName('name')} disabled={!editable}/>
              </InputColumn>
            ) : <StaticHeading name={getFieldName('name')}/>
          }
          </>
        )}
      </EditorForm>
    </>
  );
}

const mapStateToProps = (state: WebState) => ({
  getMachineTypeById: machineTypeStore.selectors.getById(state),
  machineCategoryOptions: convertToDropDownOptions(machineCategoryStore.selectors.getAsArray(state)),
  getMachineCategoryById: machineCategoryStore.selectors.getById(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertMachineType: machineTypeStore.actions.upsert,
    deleteMachineType: machineTypeStore.actions.delete
  }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(MachineTypeEditor);
