import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {createAction} from 'typesafe-actions';
import {CommonDispatch} from '../index';
import {deleteMachine, upsertMachine} from '../../../api/machineConfigurationApi';

export interface Machine {
  id: number;
  productNumber: string;
  rentalId: string;
  serialNumber: string;
  description: string;
  machineTypeId: number;
}

const selectors = createStandardSelectors(placeholder<Machine>(), s => getEntities(s).machines);
const actions = createStandardActions(placeholder<Machine>(), 'MACHINE/SET', 'MACHINE/SAVE');
export const deleteMachineAction = createAction('MACHINE/DELETE')<number>();
export type MachineActions = GetActions<typeof actions> |  ReturnType<typeof deleteMachineAction>;

export const machines = combineReducers({
  items: standardItemsReducer<Machine, MachineActions>(actions)
    .handleAction(deleteMachineAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload];
      return newState;
    })
});
export const machineStore = readonly({
  selectors: {
    ...selectors,
    getMachineByTypeId: selector(s => (typeId: number) =>
      selectors.getAsArray(s).filter(t => t.machineTypeId === typeId))
  },
  actions: {
    ...actions,
    upsert: (form: Machine) => async (dispatch: CommonDispatch) => {
      const response = await upsertMachine(form);
      dispatch(machineStore.actions.save(response));
      return response;
    },
    delete: (id: number) => async (dispatch: CommonDispatch) => {
      const response = await deleteMachine(id);
      dispatch(deleteMachineAction(response.id));
      return response;
    }
  }
});
