import React from 'react';
import Routes from './router/Routes';
import GA4React from 'ga-4-react';
import {useMount} from './hooks/useMount';

function App(_: {}) {
  const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
  const ga4react = new GA4React(gaId as string);
  useMount(async () => {
    await ga4react.initialize();
  });

  return (<Routes/>);
}
export default App;
