import React, {useState} from 'react';
import styles from '../../../Login/Login.module.scss';
import {getLogoLink} from '../../../../appTheme';
import {Form, Formik, FormikProps} from 'formik';
import Input from '../../../../components/util/form-components/formik-inputs/Input/Input';
import {propertyOf} from '../../../../common/util/object';
import Row from 'react-bootstrap/Row';
import {LoadingButton} from '../../../../components/util/widgets/LoadingButton/LoadingButton';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../../router/RoutePaths';
import {RouteComponentProps} from 'react-router';
import {handleAxiosError} from '../../../../common/util/http';
import {activateQrCode, getMachineInformationBySerial} from '../../../../api/qrCodeManagementApi';
import {Alert} from 'react-bootstrap';
import {ConfirmationDialog} from '../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {makeMachine} from '../../../../common/util/factory';

export interface ActivateQrCodeRequestForm {
  id: string;
  serialNumber: string;
}

type Props = RouteComponentProps<{id: string}>;

function ActivateQrCode(props: Props)  {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const params = new URLSearchParams(props.location.search);
  const activateRequest = {id: params.get('id'), serialNumber: ''} as ActivateQrCodeRequestForm;
  const [currentActivateRequest, setCurrentActivateRequest] = useState(activateRequest);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState(makeMachine(0));

  const onSubmit = async (form: ActivateQrCodeRequestForm) => {
    setSubmitting(true);
    setErrorMessage('');
    activateRequest.serialNumber = form.serialNumber;
    setCurrentActivateRequest(activateRequest);
    try {
      setSelectedMachine(await getMachineInformationBySerial(activateRequest.serialNumber));
    } catch (e) {
      setErrorMessage(handleAxiosError(e, {four04Msg: 'Serial # For Machine Not Found'}));
      setSubmitting(false);
      return;
    }
    setDisplayConfirmation(true);
  };

  const onConfirmation = async () => {
    try {
      await activateQrCode(currentActivateRequest);
    } catch (e) {
      setErrorMessage(handleAxiosError(e));
      setSubmitting(false);
      return;
    }
    setDisplayConfirmation(false);
    setSubmitted(true);
    window.gtag('event', 'qr_code_activated', {
      qr_code_id: currentActivateRequest.id,
      serial_number: currentActivateRequest.serialNumber
    });
  };

  return (
    <div className={styles['login-page']}>
      <div className={styles['logo']}>
        <img src={getLogoLink()} alt='ISCO Garage' style={{height: '3rem' }}/>
      </div>
      <label style={{display: 'flex', justifyContent: 'center', paddingTop: '1rem', paddingLeft: '1.5rem'}}>
        Enter the serial number of the machine to activate QR Code
      </label>
      <div className={styles['login-form']}>
        {!submitted ? (
          <>
          <Formik<ActivateQrCodeRequestForm>
            enableReinitialize={true}
            onSubmit={onSubmit}
            initialValues={{id: '', serialNumber: ''} as ActivateQrCodeRequestForm}
          >
            {(formik: FormikProps<ActivateQrCodeRequestForm>) => {
              const {handleSubmit} = formik;
              return (
                <Form onSubmit={handleSubmit}>
                  <div style={{marginBottom: '10px'}}>
                    <Input
                      name={propertyOf<ActivateQrCodeRequestForm>('serialNumber')}
                      disabled={submitting}
                      placeholder={'Serial Number'}
                      type={'text'}
                      overrideDebouncePeriod={1}
                    />
                  </div>
                  <Row className={styles['login-button']}>
                    <LoadingButton type='submit' loading={submitting} label='Submit'/>
                  </Row>
                  {errorMessage ?
                    <Alert style={{marginTop: '1rem'}} variant='danger'>{errorMessage}</Alert> : null}
                </Form>
              );
            }}
          </Formik>
          <ConfirmationDialog
            onAccept={async () => {
              await onConfirmation();
            }}
            onDecline={async () => {
              setDisplayConfirmation(false);
              setSubmitting(false);
            }}
            open={displayConfirmation}
            prompt={
              <>
                <h3>Is this the correct machine?</h3>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                  <label><b>Serial Number</b>: {selectedMachine.serialNumber}</label>
                  <label><b>Rental Id</b>: {selectedMachine.rentalId}</label>
                  <label><b>Product Number</b>: {selectedMachine.productNumber}</label>
                  <label><b>Machine Description</b>: {selectedMachine.description}</label>
                </div>
              </>
             }
            positiveText='Yes'
            negativeText='No'
            positiveVariant='danger'
          />
          </>
        ) : (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <label>QR Code Has been activated on this machine.</label>
            <div className={styles['forgot-password']}>
              <Link to={`${RoutePaths.publicPaths.watch}?id=${params.get('id')}`}>Go to landing page</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ActivateQrCode;
