import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';

export interface Video {
  id: string;
  url: VideoUrl;
  name: string;
}

export interface VideoUrl {
  directUrlPreview: string;
  play: string;
  directUrlOriginal: string;
}

const actions = createStandardActions(placeholder<Video>(), 'VIDEO/SET', 'VIDEO/SAVE');
const selectors = createStandardSelectors(placeholder<Video>(), s => getEntities(s).videos);
export type VideoActions = GetActions<typeof actions>;
export const videos = combineReducers({items: standardItemsReducer<Video, VideoActions>(actions)});
export const videoStore = readonly({
  selectors: {
    ...selectors,
    getVideoNameById: selector(s => (videoId: string) => {
      const video = selectors.getAsArray(s).filter(v => v.id === videoId);
      if (video.length === 0) {
        return {
          name: 'Video Not Found In Canto'
        };
      }
      return selectors.getAsArray(s).filter(v => v.id === videoId)[0];
    }),
    getVideoById: selector(s => (videoId: string) => {
      const video = selectors.getAsArray(s).filter(v => v.id === videoId);
      if (video.length === 0) {
        return {
          name: 'Video Not Found In Canto',
          url: {
            play: `${process.env.REACT_APP_HOST_URL}404`,
            directUrlPreview: `${process.env.REACT_APP_HOST_URL}404`,
            directUrlOriginal: `${process.env.REACT_APP_HOST_URL}404`
          }
        } as Video;
      }
      return selectors.getAsArray(s).filter(v => v.id === videoId)[0];
    })
  },
  actions: {
    ...actions
  }
});
