import {api} from './index';
import {MachineConfigurationResponse} from '../common/redux/machineConfiguration';
import {MachineCategory} from '../common/redux/entities/machineCategory';
import {MachineType} from '../common/redux/entities/machineType';
import {Machine} from '../common/redux/entities/machine';
import {Label} from '../common/redux/entities/label';

export async function getMachineConfigurationState() {
  return (await api.get<MachineConfigurationResponse>('/machineConfiguration')).data;
}

export async function upsertMachineCategory(form: MachineCategory) {
  return (await api.post<MachineCategory>('/machineCategory', form)).data;
}

export async function deleteMachineCategory(id: number) {
  return (await api.delete<MachineCategory>('/machineCategory/' + id)).data;
}

export async function upsertMachineType(form: MachineType) {
  return (await api.post<MachineType>('/machineType', form)).data;
}

export async function deleteMachineType(id: number) {
  return (await api.delete<MachineType>('/machineType/' + id)).data;
}

export async function upsertMachine(form: Machine) {
  return (await api.post<Machine>('/machine', form)).data;
}

export async function deleteMachine(id: number) {
  return (await api.delete<Machine>('/machine/' + id)).data;
}

export async function upsertLabel(form: Label) {
  return (await api.post<Label>('/label', form)).data;
}

export async function deleteLabel(id: number) {
  return (await api.delete<Label>('/label/' + id)).data;
}
