import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {createAction} from 'typesafe-actions';
import {CommonDispatch} from '../index';
import {deleteMachineType, upsertMachineType} from '../../../api/machineConfigurationApi';

export interface MachineType {
  id: number;
  name: string;
  machineCategoryId: number;
}

const selectors = createStandardSelectors(placeholder<MachineType>(), s => getEntities(s).machineTypes);
const actions = createStandardActions(placeholder<MachineType>(), 'MACHINE_TYPE/SET', 'MACHINE_TYPE/SAVE');
export const deleteMachineTypeAction = createAction('MACHINE_TYPE/DELETE')<number>();
export type MachineTypeActions = GetActions<typeof actions> |  ReturnType<typeof deleteMachineTypeAction>;

export const machineTypes = combineReducers({
  items: standardItemsReducer<MachineType, MachineTypeActions>(actions)
    .handleAction(deleteMachineTypeAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload];
      return newState;
    })
});
export const machineTypeStore = readonly({
  selectors: {
    ...selectors,
    getMachineTypesInCategory: selector(s => (categoryId: number) =>
      selectors.getAsArray(s).filter(t => t.machineCategoryId === categoryId))
  },
  actions: {
    ...actions,
    upsert: (form: MachineType) => async (dispatch: CommonDispatch) => {
      const response = await upsertMachineType(form);
      dispatch(machineTypeStore.actions.save(response));
      return response;
    },
    delete: (id: number) => async (dispatch: CommonDispatch) => {
      const response = await deleteMachineType(id);
      dispatch(deleteMachineTypeAction(response.id));
      return response;
    }
  }
});
