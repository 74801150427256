import React, {useState} from 'react';
import {WebState} from '../../../../../redux/types/WebState';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {RoutePaths} from '../../../../../router/RoutePaths';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next';
import {MachineType, machineTypeStore} from '../../../../../common/redux/entities/machineType';
import {machineCategoryStore} from '../../../../../common/redux/entities/machineCategory';
import {bindActionCreators, Dispatch} from 'redux';
import {ConfirmationDialog} from '../../../../../components/util/ConfirmationDialog/ConfirmationDialog';

type Props = RouteComponentProps<{machineCategoryId: string}> & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function MachineTypes(props: Props) {
  const {match: {params: {machineCategoryId}}, machineTypes, getMachineCategoryById, actions: {deleteMachineType}} = props;
  const [isDeleting, setIsDeleting] = useState(0);

  const getPageHeaderArea = () => {
    const machineCategory = getMachineCategoryById(Number(machineCategoryId));
    return (
      <>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Link
            to={RoutePaths.machineCategoryListPath.replace(':machineCategoryId', machineCategoryId)}
          >
            <IconButton
              icon={'angle-left'}
              size={'2x'}
              iconToolTipText={'Back'}
              color={'#005A9C'}
            />
          </Link>
          <div>
            <Link to={RoutePaths.machineCategoryListPath.replace(':machineCategoryId', String(machineCategory.id))}>
              Machine Categories
            </Link>
            <label> {' > '} </label>
            {machineCategory.name}
          </div>
          <h4>{machineCategory.name}</h4>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px', flexGrow: 1}}>
          <Link to={RoutePaths.machineTypesPaths.New.replace(':machineCategoryId', machineCategoryId)}>
            <IconButton
              icon={'plus-circle'}
              size={'2x'}
              iconToolTipText={'Add Machine Type'}
              color={'#005A9C'}
            />
          </Link>
        </div>
      </>
    );
  };

  const actionColumnFormatter = (cell: any, machineType: MachineType) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <Link
            to={RoutePaths.machineListPath.replace(':machineCategoryId', machineCategoryId)
              .replace(':machineTypeId', machineType.id.toString())}
          >
            <IconButton
              icon='pen'
              size={'1x'}
              color={'#005A9C'}
            />
          </Link>
        </div>
      </>
    );
  };

  const editMachineTypeFormatter = (cell: any, machineType: MachineType) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <Link
            to={RoutePaths.machineTypesPaths.Edit.replace(':machineCategoryId', machineCategoryId)
            .replace(':machineTypeId', machineType.id.toString())}
          >
            <IconButton
              icon='pen'
              size={'1x'}
              color={'#005A9C'}
            />
          </Link>
        </div>
      </>
    );
  };

  const deleteMachineTypeFormatter = (cell: any, machineType: MachineType) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <IconButton
            icon='minus'
            size={'1x'}
            color={'#005A9C'}
            onClick={() => setIsDeleting(machineType.id)}
          />
        </div>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true
    },
    {
      dataField: 'userActionsColumnOne',
      text: 'Manage Machines',
      isDummyField: true,
      formatter: actionColumnFormatter,
      headerStyle: () => {
        return {width: '20rem'};
      },
      style: () => {
        return {width: '20rem'};
      }
    },
    {
      dataField: 'userActionsColumnTwo',
      text: 'Edit Machine Type',
      isDummyField: true,
      formatter: editMachineTypeFormatter,
      headerStyle: () => {
        return { width: '15rem'};
      },
      style: () => {
        return { width: '15rem'};
      }
    },
    {
      dataField: 'userActionsColumnThree',
      text: 'Delete Machine Type',
      isDummyField: true,
      formatter: deleteMachineTypeFormatter,
      headerStyle: () => {
        return { width: '15rem'};
      },
      style: () => {
        return { width: '15rem'};
      }
    }
  ];
  return (
    <>
      <div style={{marginBottom: '10px', display: 'flex', alignItems: 'flex-end'}}>
        {getPageHeaderArea()}
      </div>
      <BootstrapTable keyField='id' data={machineTypes(Number(machineCategoryId))} columns={columns}/>
      {isDeleting !== 0 && (
        <ConfirmationDialog
          onAccept={async () => {
            await deleteMachineType(isDeleting);
            setIsDeleting(0);
          }}
          onDecline={async () => { setIsDeleting(0); }}
          open={isDeleting !== 0}
          prompt='Are you sure you want to delete this machine type?'
          positiveText='Yes'
          negativeText='No'
          positiveVariant='danger'
        />
      )}
    </>
  );
}

const mapStateToProps = (state: WebState) => ({
  machineTypes: machineTypeStore.selectors.getMachineTypesInCategory(state),
  getMachineCategoryById: machineCategoryStore.selectors.getById(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    deleteMachineType: machineTypeStore.actions.delete
  }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(MachineTypes);
