import * as React from 'react';
import {Switch, Route, Router} from 'react-router-dom';
import History from './history';
import NotFound from '../pages/NotFound/NotFound';
import {PrivateRoute} from '../components/util/PrivateRoute/PrivateRoute';
import Login from '../pages/Login/Login';
import {RoutePaths} from './RoutePaths';
import {LogOut} from '../pages/Logout/Logout';
import {Home} from '../pages/Home/Home';
import MachineConfiguration from '../pages/Configuration/MachineConfiguration/MachineConfiguration';
import QrCodeManagement from '../pages/Configuration/QrCodeManagement/QrCodeManagement';
import PublicRouter from '../pages/Public/PublicRouter';
import GoogleAnalytics from '../components/util/GoogleAnalytics';

const Routes = () => (
  <Router history={History}>
    <Switch>
      <Route path={RoutePaths.login} component={Login} redirectTo={RoutePaths.home}/>
      <Route path={RoutePaths.logout} component={LogOut} redirectTo={RoutePaths.login}/>
      <PrivateRoute path={RoutePaths.machineConfiguration} component={MachineConfiguration}/>
      <PrivateRoute path={RoutePaths.qrCodeManagement} component={QrCodeManagement}/>
      <PrivateRoute exact={true} path={RoutePaths.home} component={Home}/>
      <Route path={RoutePaths.notFound} component={NotFound}/>
      <Route path={RoutePaths.publicPath} component={PublicRouter}/>
      <Route component={NotFound}/>
    </Switch>
    <GoogleAnalytics history={History} match={History.location.state} location={History.location}/>
  </Router>
);

export default Routes;
