import {createStandardActions, GetActions, placeholder, readonly, standardItemsReducer} from '../utils';
import {combineReducers} from 'redux';
import {createStandardSelectors, getEntities, selector} from '../selectors';
import {CommonDispatch} from '../index';
import {createAction} from 'typesafe-actions';
import {deleteLabel, upsertLabel} from '../../../api/machineConfigurationApi';

export interface Label {
  id: number;
  name: string;
  parentId: number | null;
  machineCategoryId: number;
  videoId: string | null;
}

const selectors = createStandardSelectors(placeholder<Label>(), s => getEntities(s).labels);
const actions = createStandardActions(placeholder<Label>(), 'LABEL/SET', 'LABEL/SAVE');
export const deleteLabelAction = createAction('LABEL/DELETE')<number>();
export type LabelActions = GetActions<typeof actions> |  ReturnType<typeof deleteLabelAction>;

export const labels = combineReducers({
  items: standardItemsReducer<Label, LabelActions>(actions)
    .handleAction(deleteLabelAction, (state, action) => {
      const newState = {...state};
      delete newState[action.payload];
      return newState;
    })
});
export const labelStore = readonly({
  selectors: {
    ...selectors,
    getChildLabels: selector(s => (labelId: number, machineCategoryId: number) => {
      return selectors.getAsArray(s).filter(t => t.parentId === labelId && t.machineCategoryId === machineCategoryId);
    }),
    getRootLabels: selector(s => (machineCategoryId: number) => {
      return selectors.getAsArray(s).filter(t => t.parentId == null && t.machineCategoryId === machineCategoryId);
    }),
    getLabelsInMachineCategory: selector(s => (machineCategoryId: number) => {
      return selectors.getAsArray(s).filter(t => t.machineCategoryId === machineCategoryId);
    })
  },
  actions: {
    ...actions,
    upsert: (form: Label) => async (dispatch: CommonDispatch) => {
      const response = await upsertLabel(form);
      dispatch(labelStore.actions.save(response));
      return response;
    },
    delete: (id: string) => async (dispatch: CommonDispatch) => {
      const response = await deleteLabel(Number(id));
      dispatch(deleteLabelAction(response.id));
      return response;
    }
  },
});
