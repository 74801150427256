import React from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {connect} from 'react-redux';
import {MachineCategory, machineCategoryStore} from '../../../../../common/redux/entities/machineCategory';
import {bindActionCreators, Dispatch} from 'redux';
import {WebState} from '../../../../../redux/types/WebState';
import {EditorForm} from '../../../../../components/util/form-components/EditorForm/EditorForm';
import {RoutePaths, UrlParam} from '../../../../../router/RoutePaths';
import {useStandardEditor} from '../../../../../components/util/form-components/EditorForm/hooks';
import {makeMachineCategory} from '../../../../../common/util/factory';
import {InputColumn} from '../../../../../components/util/form-components/InputColumn';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import Labels from './components/Labels/Labels';
import IconButton from '../../../../../components/util/widgets/IconButton/IconButton';
import StaticHeading from '../../../../../components/util/form-components/formik-inputs/Heading/StaticHeading';

type Props = RouteComponentProps<{machineCategoryId: string}> & ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>;

function MachineCategoryEditor(props: Props) {
  const {match: {params: {machineCategoryId}}, getMachineCategoryById} = props;
  const applyUrl = (url: string, aTypeId: UrlParam) => url
    .replace(':machineCategoryId', String(aTypeId));
  const getFieldName = (name: keyof MachineCategory) => name;
  const getMachineCategory = () => getMachineCategoryById(Number(machineCategoryId));
  const isNew = props.match.path.indexOf('/new') !== -1;
  const standardEditor = useStandardEditor<MachineCategory>(
    'Machine Category',
    makeMachineCategory(),
    props,
    s => ({
      getEditUrl: () =>  applyUrl(RoutePaths.machineCategoryPaths.Edit, machineCategoryId),
      getCancelUrl: () =>
        s.isNew ? RoutePaths.machineCategoryListPath :
          applyUrl(RoutePaths.machineCategoryPaths.View, machineCategoryId),
      onLoadForm: async () => getMachineCategory(),
      onNewForm: async () => makeMachineCategory(),
      saveAndRedirect: async (form) => {
        const response: any = await props.actions.upsertMachineCategory(form);
        return applyUrl(RoutePaths.machineCategoryPaths.View, response.id);
      },
      onBack: () => RoutePaths.machineCategoryListPath,
      onDelete: async () => {
        await props.actions.deleteMachineCategory(Number(machineCategoryId));
        return RoutePaths.machineCategoryListPath;
      },
      footer: true,
      iconButtons: true
    }),
    RoutePaths.machineCategoryListPath);

  const getPageHeaderArea = () => {
    const machineCategory = getMachineCategoryById(Number(machineCategoryId));
    return (
      <>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Link
            to={RoutePaths.machineCategoryListPath.replace(':machineCategoryId', machineCategoryId)}
          >
            <IconButton
              icon={'angle-left'}
              size={'2x'}
              iconToolTipText={'Back'}
              color={'#005A9C'}
            />
          </Link>
          <div>
            <Link to={RoutePaths.machineCategoryListPath}>
              Machine Categories
            </Link>
            <label> {' > '} </label>
            {machineCategory !== undefined ? machineCategory.name : 'New Machine Category'}
          </div>
        </div>
      </>
    );
  };

  const {editable} = standardEditor;
  return (
    <>
      <div style={{marginBottom: '10px', display: 'flex', alignItems: 'flex-end'}}>
        {getPageHeaderArea()}
      </div>
      <EditorForm standardEditor={standardEditor}>
        {({values}) => (
          <>
            {editable ?
              (
                <InputColumn label='' columnSize={3} style={{paddingLeft: 0}}>
                  <Input name={getFieldName('name')} disabled={!editable}/>
                </InputColumn>
              ) : <StaticHeading name={getFieldName('name')}/>
            }
          </>
        )}
      </EditorForm>
      {isNew ? null :
        (
          <>
            <Labels machineCategoryId={machineCategoryId}/>
          </>
        )
      }
    </>
  );
}

const mapStateToProps = (state: WebState) => ({
  getMachineCategoryById: machineCategoryStore.selectors.getById(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({actions: bindActionCreators({
    upsertMachineCategory: machineCategoryStore.actions.upsert,
    deleteMachineCategory: machineCategoryStore.actions.delete
  }, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(MachineCategoryEditor);
