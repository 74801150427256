import {
  number,
  object, string,
} from 'yup';
import {Label} from '../../../../../../../common/redux/entities/label';

export const LabelModalSchema = object<Label>({
  id: number(),
  name: string().required('Name is required'),
  parentId: number().nullable(),
  machineCategoryId: number().required(),
  videoId: string().nullable()
});
