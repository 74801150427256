import * as React from 'react';
import {Switch, Route, match} from 'react-router-dom';
import {convertComponentPaths, RoutePath, RoutePaths} from '../../router/RoutePaths';
import {RouteRenderer} from '../../router/RouteRenderer';
import NotFound from '../NotFound/NotFound';
import ActivateQrCode from './pages/ActivateQrCode/ActivateQrCode';
import VideoSelection from './pages/VideoSelection/VideoSelection';

interface Props {
  match: match;
}

const routes: RoutePath[] = [
  ...convertComponentPaths(
    {component: VideoSelection, paths: [RoutePaths.publicPaths.watch]},
    {component: ActivateQrCode, paths: [RoutePaths.publicPaths.activate]},
  )
];

const PublicRouterRoutes = (props: Props) => (
  <Switch>
    <RouteRenderer routePaths={routes}/>
    <Route component={NotFound}/>
  </Switch>
);

export default PublicRouterRoutes;
