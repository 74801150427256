import React, {FormEvent, useState} from 'react';
import {connect} from 'react-redux';
import styles from './QrCodesModal.module.scss';
import {QrCodesModalSchema} from './QrCodeModalSchema';
import {Form, Formik, FormikErrors} from 'formik';
import {Button, Form as BSForm, Modal} from 'react-bootstrap';
import {InputRow} from '../../../../../components/util/form-components/InputRow';
import Input from '../../../../../components/util/form-components/formik-inputs/Input/Input';
import {WebState} from '../../../../../redux/types/WebState';
import {makeQrCodeGenerationRequest} from '../../../../../common/util/factory';

export interface GenerateQrCodesModalInterface {
  email: string;
  companyName: string;
  attention: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  amount: number;
}

type Props = {
  onCancel: () => void;
  onSubmit: (amount: GenerateQrCodesModalInterface) => void;
  editable: boolean;
  onTop?: boolean;
} & ReturnType<typeof mapStateToProps>;

function GenerateQrCodesModal(props: Props) {
  const {onTop, onSubmit, editable, onCancel} = props;
  const [lastSubmitted, setLastSubmitted] = useState(0);
  const getFieldName = (name: keyof GenerateQrCodesModalInterface) => name;

  const processSubmit = async (
    e: FormEvent<HTMLFormElement>,
    association: GenerateQrCodesModalInterface,
    validate: (values: GenerateQrCodesModalInterface) => Promise<FormikErrors<GenerateQrCodesModalInterface>>,
    formikHandleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void) => {
    e.persist();
    e.preventDefault();
    const errors = await validate(association);
    if (Object.values(errors).length !== 0 && Date.now() - lastSubmitted < 1000) {
      onSubmit(association);
    } else if (Object.values(errors).length !== 0) {
      formikHandleSubmit(e);
      setLastSubmitted(Date.now());
    } else {
      onSubmit(association);
    }
  };

  return (
    <Modal backdropClassName={onTop ? styles['on-top'] : ''} show={true} size='lg' centered={true} onHide={() => null}>
      <Modal.Body>
        <Formik initialValues={makeQrCodeGenerationRequest()} validationSchema={QrCodesModalSchema} onSubmit={() => undefined}>
          {({values, validateForm, handleSubmit}) => {
            return (
              <Form noValidate={true} onSubmit={(e) => processSubmit(e, values, validateForm, handleSubmit)}>
                <Modal.Title>Generate QR Codes</Modal.Title>
                <InputRow label={'Email'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('email')}/>
                </InputRow>
                <InputRow label={'Company Name'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('companyName')}/>
                </InputRow>
                <InputRow label={'Attention'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('attention')}/>
                </InputRow>
                <InputRow label={'Address 1'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('address1')}/>
                </InputRow>
                <InputRow label={'Address 2'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('address2')}/>
                </InputRow>
                <InputRow label={'City'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('city')}/>
                </InputRow>
                <InputRow label={'State'} columnSize={5} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('state')}/>
                </InputRow>
                <InputRow label={'Zip Code'} columnSize={2} style={{paddingTop: '10px'}}>
                  <Input name={getFieldName('zip')}/>
                </InputRow>
              <InputRow label={'Quantity'} columnSize={2}>
                  <Input name={getFieldName('amount')}/>
                </InputRow>
                <BSForm.Row>
                  <BSForm.Group className={styles['form-buttons']}>
                    <Button onClick={onCancel} variant={editable ? 'danger' : 'info'} className={styles['close-button']}>
                      {editable ? 'Cancel' : 'Close'}
                    </Button>
                    {editable && <Button data-tip='Double click submit to save form with errors' variant={'success'} type='submit'>Submit</Button>}
                  </BSForm.Group>
                </BSForm.Row>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
const mapStateToProps = (state: WebState) => ({});
export default connect(mapStateToProps)(GenerateQrCodesModal);
