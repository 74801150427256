import React, {useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';
import {useMount} from '../../hooks/useMount';

type Props = RouteComponentProps;

function GoogleAnalytics(props: Props) {
  const {location, history} = props;
  const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  useMount(async () => {
    ReactGA.initialize(gaId as string, {
      gaOptions: {
        siteSpeedSampleRate: 100
      }
    });
  });

  useEffect(() => {
    if (history.action === 'PUSH' || history.action === 'POP') {
      // ReactGA.pageview(location.pathname + location.search);
    }
  }, [location, history]);

  return <></>;
}

export default connect()(GoogleAnalytics);
