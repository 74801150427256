import React, {useState} from 'react';
import {connect} from 'react-redux';
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next';
import {Label, labelStore} from '../../../../../../../common/redux/entities/label';
import IconButton from '../../../../../../../components/util/widgets/IconButton/IconButton';
import {WebState} from '../../../../../../../redux/types/WebState';
import {makeLabel} from '../../../../../../../common/util/factory';
import {ConfirmationDialog} from '../../../../../../../components/util/ConfirmationDialog/ConfirmationDialog';
import {bindActionCreators, Dispatch} from 'redux';
import LabelModal from '../LabelModal/LabelModal';
import {videoStore} from '../../../../../../../common/redux/entities/video';
import styles from './Labels.module.scss';

type Props = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps> & {
  machineCategoryId: string;
};

function Labels(props: Props) {
  const {getChildLabels, getRootLabels, getVideoNameById, getLabelById, machineCategoryId, actions: {deleteLabel, upsertLabel}} = props;
  const [labelId, setLabelId] = useState('');
  const [isDeleting, setIsDeleting] = useState('');
  const [currentLabel, setCurrentLabel] = useState<Label | null>(null);
  const getLabels = () =>
    labelId === '' ? getRootLabels(Number(machineCategoryId)) : getChildLabels(Number(labelId), Number(machineCategoryId));

  const buildTreeLinks = (label: Label, makeLink: boolean = false): any => {
    if (!label.parentId) {
      return (
        <>
        {makeLink ? (
          <span onClick={() => setLabelId(String(label.id))} className={styles['fake-link']}>
            <a
              href={label.name}
              onClick={(e) => {
                e.persist();
                e.preventDefault();
              }}
            >
              {label.name}
            </a>
          </span>
        ) : label.name}
        </>
      );
    } else {
      return (
        <>
          {buildTreeLinks(getLabelById(Number(label.parentId)), true)}
          <span>{'>'}</span>
          {makeLink ? (
            <span onClick={() => setLabelId(String(label.id))} className={styles['fake-link']}>
            <a
              href={label.name}
              onClick={(e) => {
                e.persist();
                e.preventDefault();
              }}
            >
              {label.name}
            </a>
          </span>
          ) : label.name}
        </>
      );
    }
  };

  const buildLabelTreeMenu = (label: Label): any => {
    if (label === undefined) {
      return (<span>Labels</span>);
    }
    return (
      <>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <div>
            <span onClick={() => setLabelId('')} className={styles['fake-link']}>
              <a
                href={'Labels'}
                onClick={(e) => {
                  e.persist();
                  e.preventDefault();
                }}
              >
                Labels
              </a>
            </span>
            <span>{'>'}</span>
            {buildTreeLinks(label)}
          </div>
          <h3>{label.name}</h3>
        </div>
      </>
    );
  };

  const videoNameFormatter = (cell: any, label: Label) => {
    if (!label.videoId) {
      return (
        <>No Video</>
      );
    }
    const videoName = () => getVideoNameById(label.videoId!).name;
    return (
      <>
        {videoName()}
      </>
    );
  };

  const subLabelFormatter = (cell: any, label: Label) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <IconButton
            icon='pen'
            size={'1x'}
            onClick={() => setLabelId(label.id.toString())}
            color={'#005A9C'}
          />
        </div>
      </>
    );
  };

  const deleteLabelFormatter = (cell: any, label: Label) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <IconButton
            icon='minus'
            size={'1x'}
            onClick={() => setIsDeleting(label.id.toString())}
            color={'#005A9C'}
          />
        </div>
      </>
    );
  };

  const actionsFormatter = (cell: any, label: Label) => {
    return (
      <>
        <div style={{marginRight: '10px', display: 'flex', justifyContent: 'center'}}>
          <IconButton
            icon='pen'
            size={'1x'}
            onClick={() => setCurrentLabel(label)}
            color={'#005A9C'}
          />
        </div>
      </>
    );
  };

  const columns: ColumnDescription[] = [
    {
      dataField: 'name',
      text: 'Name'
    },
    {
      dataField: 'videoNameColumn',
      text: 'Video',
      isDummyField: true,
      formatter: videoNameFormatter
    },
    {
      dataField: 'actionsColumn',
      text: 'Edit Label',
      isDummyField: true,
      formatter: actionsFormatter,
      headerStyle: () => {
        return {width: '20rem'};
      },
      style: () => {
        return { width: '20rem'};
      }
    },
    {
      dataField: 'userActionsColumnOne',
      text: 'Manage Sub Labels',
      isDummyField: true,
      formatter: subLabelFormatter,
      headerStyle: () => {
        return {width: '20rem'};
      },
      style: () => {
        return { width: '20rem'};
      }
    },
    {
      dataField: 'deleteLabelAction',
      text: 'Delete Label',
      isDummyField: true,
      formatter: deleteLabelFormatter,
      headerStyle: () => {
        return {width: '20rem'};
      },
      style: () => {
        return { width: '20rem'};
      }
    }
  ];

  return (
    <>
      <div style={{display: 'flex'}}>
        <label style={{display: 'flex', flexGrow: 1, alignItems: 'center', marginBottom: '10px'}}>
          {buildLabelTreeMenu(getLabelById(Number(labelId)))}
        </label>
        <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '10px'}}>
          <IconButton
            icon='plus-circle'
            size={'2x'}
            iconToolTipText={'Add Label'}
            onClick={() => setCurrentLabel(makeLabel(Number(machineCategoryId), labelId))}
            color={'#005A9C'}
          />
        </div>
      </div>
      <BootstrapTable keyField='id' data={getLabels()} columns={columns}/>
      {isDeleting !== '' && (
        <ConfirmationDialog
          onAccept={async () => {
            deleteLabel(isDeleting);
            setIsDeleting('');
          }}
          onDecline={async () => { setIsDeleting(''); }}
          open={isDeleting !== ''}
          prompt='Are you sure you want to delete this label?'
          positiveText='Yes'
          negativeText='No'
          positiveVariant='danger'
        />
      )}
      {currentLabel ?
        <LabelModal
          editable={true}
          onSubmit={(label: Label) => {
            upsertLabel(label);
            setCurrentLabel(null);
          }}
          onCancel={() => setCurrentLabel(null)}
          label={currentLabel}
        />
        : null}
    </>
  );
}

const mapStateToProps = (state: WebState) => ({
  getLabelById: labelStore.selectors.getById(state),
  getRootLabels: labelStore.selectors.getRootLabels(state),
  getChildLabels: labelStore.selectors.getChildLabels(state),
  getVideoNameById: videoStore.selectors.getVideoNameById(state)
});
const mapDispatchToProps = (dispatch: Dispatch) => ({ actions: bindActionCreators({
    upsertLabel: labelStore.actions.upsert,
    deleteLabel: labelStore.actions.delete
  }, dispatch)});

export default connect(mapStateToProps, mapDispatchToProps)(Labels);
