export interface RoutePath {
  path: string;
  component: any;
}
const notFound = '/not-found';

enum HomePaths {
  home = '/'
}

enum MiscellaneousPaths {
  storage = '/Storage',
  images = '/images'
}

enum DashboardPaths {
  dashboard = '/dashboard'
}

enum AuthenticationPaths {
  login = '/login',
  logout = '/logout',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password'
}

enum ConfigurationPaths {
  machineConfiguration = '/configuration/machine-configuration',
  qrCodeManagement = '/configuration/qr-codes'
}

const PublicPath = '/public';

const PublicPaths = {
  activate: PublicPath + '/activate',
  watch: PublicPath + '/watch'
};

const MachineCategoryListPath = ConfigurationPaths.machineConfiguration + '/categories';
const MachineCategoryPaths = {
  New: ConfigurationPaths.machineConfiguration + '/categories/new',
  Edit: ConfigurationPaths.machineConfiguration + '/categories/edit/:machineCategoryId',
  View: ConfigurationPaths.machineConfiguration + '/categories/view/:machineCategoryId'
};

const MachineTypeListPath = MachineCategoryListPath + '/:machineCategoryId/types';
const MachineTypePaths = {
  New: MachineTypeListPath + '/new',
  Edit: MachineTypeListPath + '/edit/:machineTypeId',
  View: MachineTypeListPath + '/view/:machineTypeId'
};

const MachineListPath = MachineTypeListPath + '/:machineTypeId/machines';
const MachinePaths = {
  New: MachineListPath + '/new',
  Edit: MachineListPath + '/edit/:machineId',
  View: MachineListPath + '/view/:machineId'
};

export function applyBasePath(basePath: string, pathFn: (basePath: string) => string) {
  return pathFn(basePath);
}

export function applyBasePaths(basePath: string, ...pathsFns: Array<(basePath: string) => string>) {
  return pathsFns.map(fn => applyBasePath(basePath, fn));
}

export interface ComponentPath {
  component: any;
  paths: string[];
}

export function convertComponentPaths(...editorPaths: ComponentPath[]): RoutePath[] {
  return editorPaths.map(editorPath => editorPath.paths.map(path => ({
    path: path,
    component: editorPath.component
  }))).flat(1);
}

export type UrlParam = number|string;

export const RoutePaths = {
  ...HomePaths,
  ...MiscellaneousPaths,
  ...AuthenticationPaths,
  ...DashboardPaths,
  ...ConfigurationPaths,
  machineCategoryListPath: MachineCategoryListPath,
  machineCategoryPaths: MachineCategoryPaths,
  machineTypeListPath: MachineTypeListPath,
  machineTypesPaths: MachineTypePaths,
  machineListPath: MachineListPath,
  machinePaths: MachinePaths,
  notFound: notFound,
  publicPath: PublicPath,
  publicPaths: PublicPaths
};
