import React, {useState} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import styles from './QrCodeManagement.module.scss';
import {RouteComponentProps} from 'react-router';
import {connect} from 'react-redux';
import {WebState} from '../../../redux/types/WebState';
import {PageHeader} from '../../../components/layout/PageHeader/PageHeader';
import {bindActionCreators, Dispatch} from 'redux';
import {CenteredSpinner} from '../../../components/util/widgets/CenteredSpinner/CenteredSpinner';
import {CenteredErrorMessage} from '../../../components/util/widgets/CenteredErrorMessage/CenteredErrorMessage';
import {handleAxiosError} from '../../../common/util/http';
import {useMount} from '../../../hooks/useMount';
import {loadQrCodeManagementData} from '../../../common/redux/qrCodeManagement';
import QrCodes from './components/QrCodes/QrCodes';
import GoogleAnalytics from '../../../components/util/GoogleAnalytics';

type Props = RouteComponentProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

function QrCodeManagement(props: Props) {
  const {actions: {loadQrCodeData}} = props;
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useMount(async () => {
    try {
      await loadQrCodeData();
    } catch (e) {
      setErrorMessage(handleAxiosError(e, {connectionMsg: 'Failed to load qr code data'}));
    }
    setLoading(false);
  });

  const renderContent = () => {
    return (
      <Row noGutters={true} style={{flexGrow: 1}}>
        <Col>
          <Card className={styles['page-body']} style={{borderLeftWidth: 0, borderRightWidth: 0}}>
            <Card.Body>
              <QrCodes/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <PageHeader label='QR Code Management' removeMargin={true}>
      <Container fluid={true} className={styles['config-container']}>
        {loading ?  <CenteredSpinner/> : (
          errorMessage ? <CenteredErrorMessage message={errorMessage} /> :
            renderContent()
        )}
      </Container>
      <GoogleAnalytics {...props}/>
    </PageHeader>
  );

}

const mapDispatchToProps = (dispatch: Dispatch) =>
  ({actions: bindActionCreators({loadQrCodeData: loadQrCodeManagementData}, dispatch)});
const mapStateToProps = (state: WebState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(QrCodeManagement);
